<template>
  <div>
    <div class="pageSatatus">
      <iframe
        :src="`https://grap.giikin.cn/#/mail-list?_token=${token}&_user=${user}`"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    token() {
      return this.$store.getters.token;
    },
    user() {
      return this.$store.state.user.userInfo.data.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.pageSatatus {
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    height: calc(100vh - 90px);
  }
}
</style>
